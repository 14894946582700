import { useEffect, memo, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import { useField } from 'react-final-form'

import { useSaveButton } from 'hooks/useSaveButton'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import {
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_SECOND_DEBTOR,
  isFFGConfigWithAutoRedirectOnDacSuccess,
  isMarketingConfig
} from 'config/utils/config'
import { NavigateFunction } from 'react-router-dom'
import LegalHintTexts from 'texts/common/copy/legal'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { DacWidgetTabEnum } from 'stores/TraversalStore'
import {
  IBAN_PATH,
  usePayoutAccountValidation
} from 'hooks/usePayoutAccountValidation'
import { getButtonSizes } from './getButtonSizes'
import FormNavigation from './FormNavigationTemplate'
import { getAge } from 'utils/date'
import { YOUNG_AGE } from 'constants/components'
import { Experiments } from 'CustomOptimizelyProvider'
import getExperimentVariation from 'utils/tracking/optimizely/getExperimentVariation'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { DACEvent } from 'components/DigitalAccountCheck/helpers'

type Props = {
  onBack?: () => void
  onNext: () => void
  legalContact: string
  navigate: NavigateFunction
}

const FFGFormNavigation = observer(({ onBack, onNext, navigate }: Props) => {
  const {
    rootStore: {
      isInIframe,
      urlParams: { backUrl },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      page: { config }
    },
    rootStore
  } = useRootStore()

  const {
    page: { isFirstDisplayedPage, isLastDisplayedPage, getCurrentIndex },
    navigation: { currentPageInSession },
    traversal: {
      data,
      dacWidgetState,
      dacWidgetTab,
      overMiddleAgeDacSkipped,
      overMiddleAge3107DacOption
    }
  } = useStores()

  const [
    isIbanPresentOnPayoutAccountPage,
    setIsIbanPresentOnPayoutAccountPage
  ] = useState(false)

  const ibanField = useField(IBAN_PATH, {})
  const debtorsAge = getAge(birthday)
  const isYoungAgeConditionMet = debtorsAge < YOUNG_AGE

  useEffect(() => {
    setIsIbanPresentOnPayoutAccountPage(
      currentPageInSession === 'payoutAccount' &&
        ibanField.input.value &&
        dacWidgetState === 'initial'
    )
  }, [data, currentPageInSession, dacWidgetState])

  const isPayoutAccountReady = usePayoutAccountValidation(true)

  const { isSaveButtonVisible } = useSaveButton({
    onSuccess: () => {},
    onError: () => {}
  })

  const currentIndex = getCurrentIndex()

  const trackWithOrigin = (event: AmplitudeEvent, payload = {}) => {
    trackInAmplitude(event, { origin: 'taurine', ...payload })
  }

  const onNextWithTracking = useCallback(() => {
    if (dacWidgetTab === DacWidgetTabEnum.SLOW) {
      trackWithOrigin(AmplitudeEvent.OnDacStateChange, {
        state: 'disagreed' as FintecState
      })
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: DACEvent.STATE_CHANGE_DISAGREED,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
    }

    onNext()
  }, [onNext, dacWidgetTab, data])

  const onBackHandler = () => {
    if (
      !isMarketingConfig(config.name) &&
      isYoungAgeConditionMet &&
      dacWidgetState !== 'initial'
    ) {
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: 'final_on_back_button_click',
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
      onNext()
      return
    }
    onBack && onBack()
  }

  const isLastPage = isLastDisplayedPage(currentPageInSession)

  const experimentResultFfg = getExperimentVariation(
    Experiments.CAT_3122_DAC_9_EXPLANATORY_UI_COMMUNICATION_FFG,
    rootStore
  )

  useEffect(() => {
    if (
      (isYoungAgeConditionMet ||
        isFFGConfigWithAutoRedirectOnDacSuccess(config.name) ||
        experimentResultFfg.variation === PossibleVariants.VARIATION_B) &&
      dacWidgetState === 'success'
    ) {
      trackWithOrigin(AmplitudeEvent.OnDACSuccessAutoRedirect)
      onNextWithTracking()
    }
  }, [dacWidgetState, onNextWithTracking, config.name, isYoungAgeConditionMet])

  useEffect(() => {
    if (overMiddleAge3107DacOption) {
      getIsContinueButtonHidden()
    }
  }, [overMiddleAge3107DacOption])

  useEffect(() => {
    if (overMiddleAgeDacSkipped) {
      onNext()
    }
  }, [overMiddleAgeDacSkipped])

  const isFirstPage = isFirstDisplayedPage(currentPageInSession)

  const backLabel =
    !isInIframe || currentIndex > 0 || backUrl ? 'Zurück' : undefined

  const isDACStepInDACSeparatedConfig =
    isLastPage &&
    config.name === CONFIG_DDF_SEPARATED_DAC &&
    dacWidgetState !== 'success'

  const isBackButtonHidden =
    !backLabel || isFirstPage || isDACStepInDACSeparatedConfig

  const submitLabel = isLastPage ? 'Angebote ansehen' : 'Weiter'

  const { sizeOfContinueButton, sizeOfBackButton, sizeOfSaveButton } =
    getButtonSizes(isBackButtonHidden, isSaveButtonVisible)

  const isInDACHardRequirement = isMarketingConfig(config.name)

  const getIsContinueButtonHidden = () => {
    if (config.name === CONFIG_SECOND_DEBTOR) {
      return false
    }

    if (overMiddleAge3107DacOption && isLastPage) {
      return true
    }

    return (
      ((isInDACHardRequirement || isYoungAgeConditionMet) &&
        currentPageInSession === 'payoutAccount' &&
        dacWidgetTab === DacWidgetTabEnum.QUICK &&
        dacWidgetState !== 'success' &&
        isPayoutAccountReady &&
        !isIbanPresentOnPayoutAccountPage) ||
      isDACStepInDACSeparatedConfig
    )
  }

  const isContinueWithoutDacVisible = isDACStepInDACSeparatedConfig

  const isContinueButtonHidden = getIsContinueButtonHidden()

  return (
    <FormNavigation
      onBack={onBackHandler}
      onNext={onNext}
      navigate={navigate}
      legalText={LegalHintTexts.defaultLegalHint}
      isContinueWithoutDacVisible={isContinueWithoutDacVisible}
      isBackButtonHidden={isBackButtonHidden}
      isContinueButtonHidden={isContinueButtonHidden}
      sizeOfContinueButton={sizeOfContinueButton}
      sizeOfBackButton={sizeOfBackButton}
      sizeOfSaveButton={sizeOfSaveButton}
      submitLabel={submitLabel}
      backLabel={backLabel}
      onNextWithTracking={onNextWithTracking}
      trackWithOrigin={trackWithOrigin}
      continueButtonColor="secondary"
      continueWithoutDacButtonVariant={
        config.name === CONFIG_DDF_SEPARATED_DAC ? 'outlined' : 'text'
      }
      backButtonVariant="text"
      saveButtonVariant="contained"
    />
  )
})

export default memo(FFGFormNavigation)
