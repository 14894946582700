import countryDeOther from '../lists/country_de_other'
import bankAccountType from '../lists/bank_account_type'
import countryShort from '../lists/country_short'
import primaryEmployment from './primaryEmployment'
import secondaryEmployment from './secondaryEmployment'
import countryLong from '../lists/country_long'
import previousEmployment from './previousEmployment'
import property from './property'
import { generateNumberOptionList } from '../utils'
import activeLoansType from '../lists/active_loans_type'
import cards from '../lists/cards'
import householdLivingSituation from '../lists/household_living_situation'
import pension from '../lists/pension'
import civilStatus from '../lists/civil_status'
import gender from '../lists/gender'
import yesNoEnum from '../lists/yes_no_enum'
import pensionEnum from '../lists/pension_enum'
import activeLoansEnum from '../lists/active_loans_enum'
import numberOfSecondaryEmployments from '../lists/number_of_secondaryEmployments'

export const MALE_FIRSTNAME_PLACEHOLDER = 'z.B. Max'

const debtor = {
  activeLoans: {
    amount: {
      label: 'Ursprünglicher Kreditbetrag',
      tooltip: '',
      placeholder: 'z.B. 10.000'
    },
    bankAccount: {
      accountNumber: {
        label: 'Kontonummer des Kreditkontos',
        tooltip: '',
        placeholder: 'z.B. 0648489890'
      },
      bankCode: {
        label: 'Bankleitzahl des Kreditkontos',
        tooltip: '',
        placeholder: 'z.B. 50010517'
      },
      bic: { label: 'bic', tooltip: '', placeholder: '' },
      country: {
        label: 'Land',
        tooltip: '',
        placeholder: 'Land auswählen',
        options: countryDeOther,
        errorMap: {
          missing: 'Bitte wählen Sie ein Land aus.'
        }
      },
      iban: {
        label: 'IBAN',
        tooltip: '',
        placeholder: 'z.B. DE44 5001 0517 8247 8177 38',
        errorMap: {
          missing: 'Bitte geben Sie Ihre IBAN an.',
          invalid: 'Bitte korrigieren Sie Ihre IBAN.',
          wrong_format: 'Bitte geben Sie eine gültige IBAN an.',
          wrong_length: 'Die Länge der IBAN ist ungültig.'
        }
      },
      type: {
        label: 'Angabe zur Bankverbindung (optional)',
        tooltip: '',
        placeholder: 'Angabe zur Bankverbindung',
        options: bankAccountType
      }
    },
    dispositionAmount: {
      label: 'Verfügungsrahmen',
      tooltip: '',
      placeholder: 'Verfügungsrahmen'
    },
    endsOn: {
      label: 'Restlaufzeit bis',
      tooltip: '',
      placeholder: 'Datum der letzten Rate',
      errorMap: {
        out_of_range:
          'Der Wert muss in der Zukunft liegen und das Laufzeitende darf nicht vor dem Beginn sein'
      }
    },
    isCommonLoanWithOtherDebtor: {
      label: 'Gemeinsames Kreditkonto 1. und 2. Kreditnehmer?',
      tooltip: '',
      placeholder: 'Gemeinsames Kreditkonto 1. und 2. Kreditnehmer?',
      enums: yesNoEnum
    },
    isDebtConversionWanted: {
      label: 'Wollen Sie diesen Kredit umschulden?',
      tooltip:
        'Durch eine Umschuldung können Sie häufig Ihre Ausgaben senken. Das spart Ihnen Geld und verbessert Ihre Haushaltsrechnung – und damit Ihre Bonität. Je besser Ihre Bonität ist, desto geringer wird der Ihnen von den Banken angebotene Zinssatz sein. Um Ihr individuelles Einsparpotential berechnen zu können, holen wir unverbindliche Umschuldungsangebote für Ihre Altkredite ein. Wenn Sie auf „Ja“ klicken, können wir Ihnen bereits mit dem Kreditangebot vorausgefüllte Antragsdokumente für eine Umschuldung zusenden und hierdurch den weiteren Prozess beschleunigen. Wenn Sie „Nein“ auswählen, warten wir mit der Zusendung dieser Dokumente ab, bis Sie sich für ein von uns eingeholtes Umschuldungsangebot entschieden haben.',
      placeholder: 'Ablösen',
      enums: yesNoEnum
    },
    monthlyRate: {
      label: 'Monatliche Rate',
      tooltip: '',
      placeholder: 'z.B. 100'
    },
    remainingAmount: {
      label: 'Geschätzte Restschuld',
      tooltip: '',
      placeholder: 'z.B. 1.250',
      errorMap: {
        missing: 'Bitte geben Sie Ihre geschätzte Restschuld an.',
        out_of_range:
          'Die angegebene Restschuld ist größer als der ursprüngliche Kreditbetrag.'
      }
    },
    startedOn: {
      label: 'Kreditbeginn',
      tooltip: '',
      placeholder: 'Datum der ersten Rate',
      errorMap: {
        missing: 'Bitte geben Sie das Datum der ersten Rate an.',
        invalid: 'Bitte geben Sie ein gültiges Datum für die erste Rate an.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).',
        out_of_range: 'Das Datum darf maximal ein Jahr in der Zukunft liegen.'
      }
    },
    type: {
      label: 'Kreditart',
      tooltip: '',
      placeholder: 'Kreditart auswählen',
      options: activeLoansType,
      errorMap: {
        missing: 'Bitte geben Sie die Kreditart an.'
      }
    }
  },
  contact: {
    email: {
      label: 'E-Mail',
      tooltip:
        'An diese E-Mail Adresse werden alle wichtigen Anträge, Unterlagen zu Abschlüssen und Vergleichen geschickt. Bitte geben Sie eine gültige E-Mail Adresse an.',
      placeholder: 'z.B. max.muster@gmail.com',
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihre E-Mail Adresse mit.',
        invalid: 'Bitte korrigieren Sie die E-Mail Adresse.',
        wrong_format: 'Bitte geben Sie eine gültige E-Mail Adresse an.'
      }
    },
    phoneNumber: {
      label: 'Mobilfunknummer',
      tooltip: '',
      placeholder: 'z.B. +49 172 9925904',
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihre Mobilfunknummer mit.',
        invalid: 'Bitte korrigieren Sie die Mobilfunknummer.',
        wrong_format: 'Bitte geben Sie eine gültige Rufnummer an.'
      }
    }
  },
  currentAddress: {
    city: {
      label: 'Wohnort',
      tooltip: '',
      placeholder: 'z.B. Musterstadt',
      errorMap: {
        missing: 'Bitte geben Sie einen Wohnort an.'
      }
    },
    country: {
      label: 'Land',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'Land auswählen',
      options: countryShort,
      errorMap: {
        missing: 'Bitte wählen Sie ein Land aus.'
      }
    },
    houseNumber: {
      label: 'Hausnummer',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'z.B. 73',
      errorMap: {
        missing: 'Bitte geben Sie eine Hausnummer an.',
        invalid: 'Bitte korrigieren Sie die Hausnummer.',
        wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
      }
    },
    street: {
      label: 'Straße und Hausnummer',
      tooltip:
        'Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'z.B. Musterstraße 13',
      errorMap: {
        missing: 'Bitte geben Sie eine Straße an.',
        invalid: 'Bitte korrigieren Sie die Straße.',
        wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
      }
    },
    zipCode: {
      label: 'PLZ',
      tooltip: '',
      placeholder: 'z.B. 11011',
      errorMap: {
        missing: 'Bitte geben Sie eine Postleitzahl an.',
        invalid: 'Bitte korrigieren Sie die Postleitzahl.',
        wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
        wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
      }
    }
  },
  currentEmployment: primaryEmployment,
  expenses: {
    additionalHousingCosts: {
      label: 'Wohnnebenkosten',
      tooltip:
        'Bitte geben Sie Ihre monatlichen Nebenkosten wie Strom, Wasser oder Müllkosten ein.',
      placeholder: 'z.B. 150',
      errorMap: {
        missing: 'Bitte geben Sie Ihre monatlichen Nebenkosten an.',
        invalid:
          'Bitte korrigieren Sie den Wert Ihrer monatlichen Nebenkosten.',
        wrong_format: 'Bitte geben Sie einen gültigen Wert an.'
      }
    },
    cards: {
      label: 'Besitzen Sie EC- oder Kreditkarten?',
      tooltip: '',
      placeholder: 'Besitzen Sie EC-/Kreditkarten',
      options: cards,
      errorMap: {
        missing:
          'Bitte teilen Sie uns mit, ob Sie EC- oder Kreditkarten besitzen.'
      }
    },
    childSupport: {
      label: 'Zu zahlender Kindesunterhalt',
      tooltip:
        'Falls gerichtlich festgelegt ist, dass Sie Unterhalt für Kinder zahlen müssen, geben Sie bitte die Gesamtsumme dieser monatlichen Unterhaltsverpflichtungen an.',
      placeholder: 'z.B. 406',
      errorMap: {
        missing:
          'Bitte geben Sie die Gesamtsumme der monatlichen Unterhaltsverpflichtungen an.',
        invalid: 'Bitte korrigieren Sie die angegebene Gesamtsumme.',
        wrong_format:
          'Bitte geben Sie einen gültigen Wert für die Gesamtsumme an.'
      }
    },
    hasCar: {
      label: 'Besitzen Sie einen PKW?',
      tooltip: '',
      placeholder: 'Besitzen Sie einen PKW',
      enums: yesNoEnum,
      errorMap: {
        missing: 'Bitte teilen Sie uns mit, ob Sie ein Kraftfahrzeug besitzen.'
      }
    },
    healthInsuranceType: {
      label: 'Sind Sie privat krankenversichert? (ohne Zusatzversicherungen)',
      tooltip: 'Bitte geben Sie an, ob Sie privat krankenversichert sind?',
      placeholder:
        'Sind Sie privat krankenversichert? (ohne Zusatzversicherungen)',
      errorMap: {
        missing: 'Bitte geben Sie an, ob Sie privat krankenversichert sind?'
      }
    },
    homePurchaseSavingsRate: {
      label: 'Ausgaben für Bausparen',
      tooltip: '',
      placeholder: 'z.B. 200',
      errorMap: {
        missing:
          'Bitte teilen Sie uns mit, wie viel Sie monatlich für Bausparen investieren.',
        invalid: 'Bitte korrigieren Sie den monatlichen Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Wert für den Betrag an.'
      }
    },
    isHomePurchaseSavingsUsedForMortgageRepayment: {
      label:
        'Dient der Bausparvertrag zur Tilgung Ihres Baufinanzierungsdarlehens?',
      tooltip: '',
      placeholder: '',
      enums: yesNoEnum
    },
    isLifeInsuranceUsedForMortgageRepayment: {
      label:
        'Dient der Lebensversicherungsvertrag zur Tilgung Ihres Baufinanzierungsdarlehens?',
      tooltip: '',
      placeholder: '',
      enums: yesNoEnum
    },
    lifeInsurance: {
      label: 'Ausgaben für Lebensversicherung',
      tooltip: '',
      placeholder: 'z.B. 5'
    },
    numberOfChildrenWithChildSupport: {
      label: 'Unterhaltspflichtige Kinder',
      tooltip:
        'Bitte wählen Sie die Anzahl der Kinder aus, für die Sie Unterhalt zahlen.',
      placeholder: 'Unterhaltspflichtige Kinder',
      singleInfoLabel: 'Anzahl unterhaltspflichtige Kinder'
    },
    mortgageRateUsedProperty: {
      label: 'Baufinanzierungskosten selbstgenutzte Immobilie',
      tooltip:
        'Bitte geben Sie an, wie hoch die monatliche Finanzierungsrate für Ihr Wohneigentum ist.',
      placeholder: 'z.B. 1.050'
    },
    mortgageRateRentalProperty: {
      label: 'Baufinanzierungskosten vermietete Immobilie',
      tooltip:
        'Bitte geben Sie an, wie hoch die monatliche Finanzierungsrate für Ihre vermietete Immobilie ist.',
      placeholder: 'z.B. 1.050'
    },
    privateHealthInsuranceRate: {
      label: 'Private Krankenversicherung',
      tooltip: '',
      placeholder: '250'
    },
    rent: {
      label: 'Warmmiete',
      tooltip:
        'Hinweis für Mieter:\nBitte geben Sie als Warmmiete den Betrag ein, den Sie monatlich an Ihren Vermieter überweisen.\n\nHinweis, wenn Sie mietfrei oder bei den Eltern wohnen:\nBitte geben Sie die Höhe der ggf. monatlich von Ihnen zu zahlenden Kosten ein.',
      placeholder: 'z.B. 750',
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihre Warmmiete mit.',
        invalid: 'Bitte korrigieren Sie den Betrag Ihrer Warmmiete.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
      }
    },
    spousalSupport: {
      label: 'Ehegattenunterhalt (nach Scheidung)',
      tooltip:
        'Falls Sie Unterhalt für frühere Partner/innen zahlen, geben Sie bitte die monatliche Gesamtsumme dieser Unterhaltsverpflichtungen an.',
      placeholder: 'z.b. 800',
      errorMap: {
        missing:
          'Bitte teilen Sie uns die Gesamtsumme der Unterhaltsverpflichtungen mit.',
        invalid: 'Bitte korrigieren Sie die angegebene Gesamtsumme.',
        wrong_format: 'Bitte geben Sie eine gültige Gesamtsumme an.'
      }
    }
  },
  hasMinijob: {
    label: 'Haben Sie einen Aushilfs- oder Minijob?',
    tooltip: '',
    placeholder: '',
    enums: yesNoEnum,
    errorMap: {
      missing:
        'Bitte teilen Sie uns mit, ob Sie einen Aushilfs- oder Minijob besitzen.'
    }
  },
  hasPreviousEmployment: {
    label:
      'Waren Sie vor Ihrer jetzigen Tätigkeit bei einem Arbeitgeber angestellt?',
    labelOpposite:
      'Waren Sie vor Ihrer jetzigen Tätigkeit bei einem Arbeitgeber angestellt?',
    tooltip: '',
    placeholder: 'Vorherige Beschäftigung?'
  },
  household: {
    livingSituation: {
      label: 'Wohnsituation',
      tooltip: '',
      placeholder: 'Wohnsituation',
      options: householdLivingSituation,
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihre aktuelle Wohnsituation mit.'
      }
    },
    numberOfChildren: {
      label: 'Kinder im Haushalt',
      infoLabel: '0 - unter 18 Jahre',
      singleInfoLabel: 'Anzahl Kinder im Haushalt (unter 18 Jahre)',
      tooltip: '',
      placeholder: 'Kinder im Haushalt',
      errorMap: {
        missing:
          'Bitte teilen Sie uns mit, wie viele Kinder in Ihrem Haushalt leben.'
      }
    },
    numberOfResidents: {
      label: 'Erwachsene im Haushalt',
      infoLabel: 'ab 18 - 99+ Jahre',
      singleInfoLabel: 'Anzahl Erwachsene im Haushalt (ab 18 Jahre)',
      tooltip: '',
      placeholder: 'Erwachsene im Haushalt',
      errorMap: {
        missing:
          'Bitte teilen Sie uns mit, wie viele erwachsene Personen in Ihrem Haushalt leben.'
      }
    }
  },
  income: {
    childSupport: {
      label: 'Eingehender Kindesunterhalt',
      tooltip: '',
      placeholder: 'z.B. 812',
      errorMap: {
        missing:
          'Bitte teilen Sie uns Ihre monatlichen Einnahmen durch Unterhaltszahlungen mit.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
      }
    },
    hasFurtherIncome: {
      label: 'Sonstige Einkünfte?',
      tooltip: '',
      placeholder: 'Sonstige Einkünfte?',
      enums: yesNoEnum,
      errorMap: {
        missing: 'Bitte geben Sie an, ob Sie über weitere Einnahmen verfügen.'
      }
    },
    interest: { label: 'interest', tooltip: '', placeholder: '' }, // TODO check if used
    isMinijobIncomeStable: {
      label: 'War das Einkommen in den letzten 3 Monaten konstant?',
      tooltip: '',
      enums: yesNoEnum,
      placeholder: ''
    },
    isPrimaryIncomeStable: {
      label:
        'Gab es bei Ihrem Einkommen monatliche Abweichungen von mehr als 100 € in den letzten 3 Monaten?',
      tooltip: '',
      enums: yesNoEnum,
      placeholder: ''
    },
    minijob: {
      label: 'Nettoeinkommen Aushilfs-/Minijob',
      tooltip:
        'Wieso fragen wir das?\nDiese Information ist zusammen mit den weiteren Ausgaben bezüglich Ihrer monatlichen Einnahmen und Ausgaben wichtig für Ihre Haushaltsrechnung.\n\nBitte geben Sie an über welches nachweisbare Nettoeinkommen pro Monat (ohne Kindergeld) Sie aus dieser Tätigkeit verfügen.',
      placeholder: 'z. B. 430',
      errorMap: {
        missing: 'Bitte geben Sie Ihr monatliches Nettoeinkommen an.',
        invalid: 'Bitte korrigieren Sie Ihr monatliches Nettoeinkommen.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
      }
    },
    numberOfChildrenWithChildBenefits: {
      label: 'kindergeldberechtigter Kinder',
      tooltip:
        'Bitte wählen Sie aus, für wie viele Kinder Sie momentan vom Staat Kindergeld erhalten.',
      singleInfoLabel: 'Anzahl kindergeldberechtigter Kinder',
      placeholder: 'kindergeldberechtigter Kinder',
      options: generateNumberOptionList(0, 10),
      errorMap: {
        missing: 'Bitte geben Sie die Anzahl kindergeldberechtiger Kinder an.'
      }
    },
    numberOfChildrenWithChildSupport: {
      label: 'Unterhaltsberechtigte Kinder',
      tooltip:
        'Bitte wählen Sie aus, für wie viele Kinder Sie momentan Unterhalt erhalten.',
      placeholder: 'Unterhaltsberechtigte Kinder',
      errorMap: {
        missing: 'Bitte geben Sie die Anzahl unterhaltsberechtigter Kinder an.'
      },
      singleInfoLabel: 'Anzahl unterhaltsberechtigte Kinder'
    },
    numberOfSecondaryEmployments: {
      label: 'Nebentätigkeiten',
      tooltip: '',
      placeholder: 'Nebentätigkeiten',
      enums: numberOfSecondaryEmployments
    },
    numberOfPensions: {
      label: 'Renten',
      tooltip: '',
      singleInfoLabel: 'Anzahl Renten',
      placeholder: 'Renten',
      enums: pensionEnum
    },
    pension: {
      label: 'Rente (netto)',
      tooltip:
        'Bitte tragen Sie hier die Summe Ihrer nachweisbaren zusätzlichen monatlichen Renteneinnahmen ein.',
      placeholder: 'z.B. 950',
      errorMap: {
        missing:
          'Bitte geben Sie die Summe Ihrer monatlichen Renteneinnahmen an.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie eine gültige Summe an.'
      }
    },
    pensions: {
      income: {
        label: 'Rente (netto)',
        tooltip: '',
        placeholder: 'z.B. 950',
        errorMap: {
          missing: 'Bitte geben Sie Ihre monatliche Rente an.',
          invalid: 'Bitte korrigieren Sie den Betrag.',
          wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
        }
      },
      isTemporary: {
        label: 'Rente befristet?',
        tooltip: '',
        placeholder: 'Einnahmen befristet?',
        enums: yesNoEnum,
        errorMap: {
          missing: 'Bitte teilen Sie uns mit, ob Ihre Rente befristet ist.'
        }
      },
      type: {
        label: 'Art der Rente',
        tooltip: '',
        placeholder: 'Art der Rente auswählen',
        options: pension,
        errorMap: {
          missing: 'Bitte geben Sie die Art der Rente an.'
        }
      },
      validUntil: {
        label: 'Befristet bis',
        tooltip: '',
        placeholder: 'befristet bis'
      }
    },
    primary: {
      label: 'Nettoeinkommen',
      tooltip:
        'Das monatliche Einkommen wird für Ihre Haushaltsrechnung benötigt. Diese wird von den Banken durchgeführt, um Ihr Kreditangebot zu ermitteln.',
      placeholder: 'z.B. 1.745',
      errorMap: {
        missing:
          'Bitte geben Sie das Nettoeinkommen aus Ihrer Haupttätigkeit an.',
        invalid: 'Bitte korrigieren Sie den Betrag.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
      }
    },
    rent: {
      label: 'Einnahmen durch Vermietung (Warmmiete)',
      tooltip:
        'Falls Sie über monatliche Einnahmen aus der Vermietung von Immobilien verfügen, geben Sie bitte die Gesamtsumme aller Warmmieten in Euro an.',
      placeholder: 'z. B. 1.000',
      errorMap: {
        missing: 'Bitte geben Sie die Gesamtsumme aller Ihrer Warmmieten an.',
        invalid: 'Bitte korrigieren Sie die Gesamtsumme.',
        wrong_format: 'Bitte geben Sie eine gültige Summe an.'
      }
    },
    secondaryEmployments: secondaryEmployment,
    spousalSupport: {
      label: 'Ehegattenunterhalt',
      tooltip:
        'Bitte geben Sie monatliche Einnahmen aus Ehegatten-Unterhaltszahlungen an.\n\nWichtig: Die Zahlungen müssen nachweisbar sein, z.B. durch einen gerichtlichen Unterhaltstitel oder durch Ihre Kontoauszüge.',
      placeholder: 'z.B. 1.200',
      errorMap: {
        missing: 'Bitte geben Sie die monatlichen Einnahmen an.',
        invalid: 'Bitte korrigieren Sie die Einnahmen.',
        wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
      }
    }
  },
  numberOfActiveLoans: {
    label: 'Bestehende Kredite',
    tooltip:
      'Bitte geben Sie an, über wie viele laufende Kredite (z.B. Konsumentenkredite, Autokredite, Leasingfinanzierungen, Ballonfinanzierungen, 0%&nbsp;Finanzierungen) Sie verfügen.\nLaufende Baufinanzierungsraten sind hier bitte nicht zu berücksichtigen.',
    placeholder: 'Bestehende Kredite',
    enums: activeLoansEnum
  },
  pensionFund: {
    address: {
      city: {
        label: 'Ort Rentenkasse',
        tooltip: '',
        placeholder: 'z.B. Musterstadt',
        errorMap: {
          missing: 'Bitte geben Sie den Ort der Rentenkasse an.',
          wrong_format: 'Bitte geben Sie einen gültigen Ort an.'
        }
      },
      country: {
        label: 'Land Rentenkasse',
        tooltip: '',
        placeholder: 'Land auswählen',
        options: countryShort,
        errorMap: {
          missing: 'Bitte wählen Sie das Land Ihrer Rentenkasse aus.'
        }
      },
      houseNumber: {
        label: 'Hausnummer Rentenkasse',
        tooltip: '',
        placeholder: 'z.B. 73',
        errorMap: {
          missing: 'Bitte geben Sie die Hausnummer Ihrer Rentenkasse an.',
          invalid: 'Bitte korrigieren Sie die Hausnummer.',
          wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
        }
      },
      street: {
        label: 'Straße und Hausnummer Rentenkasse',
        tooltip: '',
        placeholder: 'z.B. Musterstraße 133',
        errorMap: {
          missing: 'Bitte geben Sie die Straße Ihrer Rentenkasse an.',
          invalid: 'Bitte korrigieren Sie die Straße.',
          wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
        }
      },
      zipCode: {
        label: 'PLZ Rentenkasse',
        tooltip: '',
        placeholder: 'z.B. 11011',
        errorMap: {
          missing: 'Bitte geben Sie die Postleitzahl Ihrer Rentenkasse an.',
          invalid: 'Bitte korrigieren Sie die Postleitzahl.',
          wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
          wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
        }
      }
    },
    name: {
      label: 'Name Rentenkasse',
      tooltip:
        'Wieso fragen wir das?\nDamit die Bank einen Abgleich mit dem Einkommensnachweis durchführen kann.',
      placeholder: 'z.B. Deutsche Rentenversicherung',
      errorMap: {
        missing: 'Bitte geben Sie den Namen der Rentenkasse ein.',
        invalid: 'Bitte korrigieren Sie den Namen Ihrer Rentenkasse.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    },
    retiredSince: {
      label: 'Rente wird bezogen seit',
      tooltip: 'Bitte geben Sie an, seit wann Sie in Rente/Pension sind.',
      placeholder: '12/2009',
      errorMap: {
        missing: 'Bitte geben Sie ein Datum an.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    }
  },
  personal: {
    birthday: {
      label: 'Geburtsdatum',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'TT.MM.JJJJ',
      errorMap: {
        missing: 'Bitte geben Sie Ihr Geburtsdatum an.'
      }
    },
    birthplace: {
      label: 'Geburtsort',
      tooltip: '',
      placeholder: 'z.B. Musterstadt',
      errorMap: {
        missing: 'Bitte geben Sie Ihren Geburtsort an.'
      }
    },
    civilStatus: {
      label: 'Familienstand',
      tooltip: '',
      placeholder: 'Familienstand',
      options: civilStatus,
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihren Familienstand mit.'
      }
    },
    firstname: {
      label: 'Vorname',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: MALE_FIRSTNAME_PLACEHOLDER,
      errorMap: {
        missing: 'Bitte geben Sie Ihren Vornamen an.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    },
    gender: {
      label: 'Anrede',
      tooltip: '',
      placeholder: 'Anrede',
      options: gender,
      errorMap: {
        missing: 'Bitte wählen Sie eine Anrede aus.'
      }
    },
    lastname: {
      label: 'Nachname',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'z.B. Mustermann',
      errorMap: {
        missing: 'Bitte geben Sie Ihren Nachnamen an.',
        wrong_format: 'Bitte geben Sie einen gültigen Namen an.'
      }
    },
    livingInGermanySince: {
      label: 'In Deutschland lebend seit',
      tooltip: '',
      placeholder: 'In Deutschland lebend seit',
      errorMap: {
        missing: 'Bitte geben Sie ein Datum an.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    },
    nationality: {
      label: 'Staatsangehörigkeit',
      tooltip:
        'Wichtig: Bitte achten Sie darauf, dass Ihre Angabe mit Ihrem Personalausweis oder Reisepass übereinstimmt.',
      placeholder: 'Staatsangehörigkeit auswählen',
      options: countryLong,
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihre Staatsangehörigkeit mit.'
      }
    },
    countryOfBirth: {
      label: 'Geburtsland',
      tooltip: '',
      placeholder: 'Geburtsland auswählen',
      options: countryLong,
      errorMap: {
        missing: 'Bitte teilen Sie uns Ihr Geburtsland mit.'
      }
    }
  },
  previousAddress: {
    city: {
      label: 'Wohnort',
      tooltip: '',
      placeholder: 'z.B. Musterstadt',
      errorMap: {
        missing: 'Bitte geben Sie Ihren vorherigen Wohnsitz an.',
        wrong_format: 'Bitte geben Sie einen gültigen Ort an.'
      }
    },
    country: {
      label: 'Land',
      tooltip: '',
      placeholder: 'Land auswählen',
      options: countryShort,
      errorMap: {
        missing: 'Bitte geben Sie das Land Ihres vorherigen Wohnsitzes an.'
      }
    },
    houseNumber: {
      label: 'Hausnummer',
      tooltip: '',
      placeholder: 'z.B. 73',
      errorMap: {
        missing:
          'Bitte geben Sie die Hausnummer Ihres vorherigen Wohnsitzes an.',
        invalid: 'Bitte korrigieren Sie die Hausnummer.',
        wrong_format: 'Bitte geben Sie eine gültige Hausnummer an.'
      }
    },
    street: {
      label: 'Straße und Hausnummer',
      tooltip: '',
      placeholder: 'z.B. Musterstraße 13',
      errorMap: {
        missing: 'Bitte geben Sie die Straße Ihres vorherigen Wohnsitzes an.',
        invalid: 'Bitte korrigieren Sie die Straße.',
        wrong_format: 'Bitte geben Sie einen gültigen Straßennamen an.'
      }
    },
    zipCode: {
      label: 'PLZ',
      tooltip: '',
      placeholder: '11011',
      errorMap: {
        missing:
          'Bitte geben Sie die Postleitzahl Ihrers vorherigen Wohnsitzes an.',
        invalid: 'Bitte korrigieren Sie die Postleitzahl.',
        wrong_format: 'Bitte geben Sie eine gültige Postleitzahl an.',
        wrong_length: 'Die Länge der Postleitzahl ist ungültig.'
      }
    }
  },
  previousEmployment,
  realEstate: {
    rentalProperty: property('vermietete'),
    selfUsedProperty: property('selbstgenutzte')
  },
  residentAlien: {
    isResidencePermitTemporary: {
      label: 'Aufenthaltsgenehmigung befristet?',
      tooltip: '',
      placeholder: 'Aufenthaltsgenehmigung befristet?',
      errorMap: {
        missing:
          'Bitte geben Sie an, ob Ihre Aufenthaltsgenehmigung befristet ist.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    },
    isWorkPermitTemporary: {
      label: 'Arbeitserlaubnis befristet?',
      tooltip: '',
      placeholder: 'Arbeitserlaubnis befristet?',
      errorMap: {
        missing: 'Bitte geben Sie an, ob Ihre Arbeitserlaubnis befristet ist.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    },
    residencePermitValidUntil: {
      label: 'Aufenthalt befristet bis',
      tooltip:
        'Geben Sie hier das Datum an, wann die Befristung der Aufenthaltsgenehmigung endet.',
      placeholder: 'Aufenthalt befristet bis',
      errorMap: {
        missing: 'Bitte geben Sie an, ob Ihr Aufenthalt befristet ist.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    },
    workPermitValidUntil: {
      label: 'Erlaubnis befristet bis',
      tooltip:
        'Geben Sie hier bitte an, wann die Befristung der Arbeitserlaubnis endet.',
      placeholder: 'Erlaubnis befristet bis',
      errorMap: {
        missing:
          'Bitte geben Sie an bis wann Ihre Arbeitserlaubnis befristet ist.',
        invalid: 'Bitte korrigieren Sie das Datum.',
        wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
      }
    }
  },
  residentSince: {
    label: 'Dort wohnhaft seit',
    tooltip:
      'Bitte geben Sie an, seit wann Sie an dieser Adresse wohnhaft sind.',
    placeholder: 'z.B. 12/1999',
    errorMap: {
      missing:
        'Bitte geben Sie an, seit wann Sie an dieser Adresse wohnhaft sind.',
      invalid: 'Bitte korrigieren Sie das Datum.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (MM.JJJJ).'
    }
  }
}

export default debtor
