import { memo } from 'react'
import Rating from '@mui/material/Rating'
import { Typography, Grid } from '@mui/material'
import { useClientDetails } from 'stores/utils/hooks/useClientDetails'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = () => ({
  ratingStars: {
    '& svg': {
      height: '1.125rem',
      width: '1.125rem'
    }
  }
})

const RceEkomi = () => {
  const clientDetails = useClientDetails()
  const { ratingStars } = useEmotionStyles(styles)

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      flexWrap="nowrap"
      gap={0.5}
    >
      <Grid item>
        <img
          src={clientDetails?.logos?.ekomi}
          alt="Ausgezeichnete Kundenzufriedenheit Logo"
          height="40px"
          data-testid="ekomi-image"
        />
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        <Grid item>
          <Typography
            variant="caption"
            color="textSecondary"
            display="flex"
            fontSize="0.625rem"
            lineHeight="1.5"
          >
            smava.de - 4,9/5
          </Typography>
        </Grid>
        <Grid item display="flex">
          <Rating
            className={ratingStars}
            name="read-only"
            value={4.9}
            readOnly
          />
        </Grid>

        <Grid item>
          <Typography
            display="flex"
            fontSize="0.375rem"
            lineHeight="1.5"
            variant="caption"
            color="textSecondary"
          >
            aus 1430 Bewertungen
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(RceEkomi)
