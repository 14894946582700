import { observer } from 'mobx-react'
import { useStores } from 'stores/utils/hooks/useStores'
import { LegalText } from 'components/DigitalAccountCheck/IntroText/LegalText'
import { DAC_IDLE_STEPS } from 'components/DigitalAccountCheck/helpers'
import { getFooterSeals } from 'components/Footer/getSeals'
import Seals from 'components/Footer/Seals'
import { TuevLink } from './TuevLink'

const DacFooterWrapperFfg = observer(() => {
  const {
    traversal: { dacWidgetStep }
  } = useStores()

  const isDacInProgress = !DAC_IDLE_STEPS.includes(dacWidgetStep)

  return (
    <>
      <TuevLink />
      <Seals seals={getFooterSeals()} itemMargin={16} />
      {isDacInProgress && <LegalText />}
    </>
  )
})

export { DacFooterWrapperFfg }
