const introduction = {
  headline: 'Warum fragen wir das?',
  headlineAlternative: 'Warum benötigen wir diese Information?',
  debtorCount: {
    betterConditions: {
      label: 'Mit einem zweiten Kreditnehmer wird der Kredit oft günstiger',
      value:
        'Mit einem zweiten Kreditnehmer erhöht sich für die Banken die Wahrscheinlichkeit, dass ein Kredit zurückgezahlt wird. Dieses verringerte Kreditausfallrisiko wird belohnt: mit einem besseren Zinssatz, längeren Laufzeiten oder auch höheren Kreditbeträgen.'
    },
    betterConditionsShort: {
      label: 'Tipp für günstige Konditionen:',
      value:
        'Ein zweiter Kreditnehmer erhöht die Wahrscheinlichkeit, einen günstigeren Zinssatz zu erhalten.'
    }
  },
  contactInfo: {
    individuality: {
      label: 'Individuelle Angaben für Ihr individuelles Angebot',
      value:
        'Die Angaben zu Ihrer Person dienen zur Bestimmung Ihrer Bonität. Außerdem können wir Ihnen so Ihre persönlichen Kredit-Angebote zukommen lassen.',
      valueAlternative:
        'Nur mit den Angaben zu Ihrer Person ermitteln wir das bestmögliche Angebot für Sie. Dabei brauchen Sie sich keine Sorgen machen, denn Ihre Informationen werden von uns vertraulich behandelt und verschlüsselt übermittelt.'
    }
  },
  personalInfo: {
    identityCheck: {
      label: 'Kreditinstitute müssen Ihre Identität prüfen',
      value:
        'Kreditinstitute sind durch § 10 Absatz 1 GwG verpflichtet, Angaben zur Person des Kreditnehmers zu erheben und dessen Identität zu überprüfen.\nIhr Vorteil: Alle Angaben sind 100% Schufaneutral und haben keine Auswirkungen auf Ihren Schufa-Score.'
    }
  },
  address: {
    disclaimer: {
      label:
        'Kreditinstitute sind dazu verpflichtet, Ihre Identität zu überprüfen. Nur mit den Angaben zu Ihrer Person kann das bestmögliche Angebot für Sie ermittelt werden.',
      value:
        'Ihre Angaben werden von smava vertraulich behandelt und verschlüsselt übermittelt. Der Schutz Ihrer persönlichen Daten hat für uns höchste Bedeutung. <br><br> Ein Kreditvergleich über smava.de ist kostenlos, unverbindlich und SCHUFA- neutral. Ihre "Anfrage Kreditkonditionen" hat keinerlei Auswirkungen auf Ihren SCHUFA-Score.'
    },
    currentAddress: {
      residence: {
        label: 'Ihr Wohnort beeinflusst Ihre Kreditwürdigkeit',
        value:
          'Verbraucherkredite werden grundsätzlich nur an Kreditnehmer mit festem Wohnsitz vergeben.'
      }
    },
    previousAddress: {
      settled: {
        label: 'Je sesshafter, desto besser',
        value:
          'Wer häufiger den Wohnsitz wechselt, ist aus Sicht der Banken schwerer auffindbar. Viele Banken verlangen deshalb eine Angabe über Ihren vorherigen Wohnsitz, wenn Sie weniger als zwei Jahre an Ihrem aktuellen Wohnsitz wohnen.'
      }
    }
  },
  schufaNeutral: {
    label: '# sadasdasd',
    value:
      'Wir unterscheiden zwischen Konditionenanfragen und Kreditanfragen. Wir melden eine Konditionenanfrage bei der SCHUFA, die keine negativen Auswirkungen auf Ihren Score hat. Diese hat auch keinen Einfluss auf Ihre Kreditwürdigkeit. Potentielle Vertragspartner sehen diesen Eintrag nicht. Deshalb ist unser Kreditvergleich SCHUFA-neutral.'
  },
  household: {
    solvency: {
      label: 'Wie Sie wohnen, beeinflusst Ihre Bonität',
      value:
        'Ihre Wohnsituation wird bei der Kreditprüfung berücksichtigt. Dabei kann relevant sein, mit wie vielen Personen Sie zusammenleben und ob Sie zur Miete oder im Eigenheim wohnen. So ist es bspw. möglich, dass Sie als Eigenheimbesitzer besonders günstige Kreditkonditionen erhalten.'
    }
  },
  occupation: {
    occupationGroup: {
      interest: {
        label:
          'Unterschiedliche Berufsgruppen erhalten unterschiedliche Zinsen',
        value:
          'Die Höhe des Zinssatzes, Vertragslaufzeit und Kreditsumme richten sich auch nach Ihrer Berufsgruppe.',
        valueAlternative:
          'Wenn Sie einer bestimmten Berufsgruppe angehören, profitieren Sie von Sonderkonditionen wie niedrigen Zinssätzen, hohen Kreditsummen oder langen Laufzeiten.'
      }
    },
    profession: {
      discretion: {
        label: 'Diskretion garantiert',
        value:
          'Die Informationen, die Sie hier angeben, dienen nur dazu, sicherzustellen, dass Sie sich in einem festen Anstellungsverhältnis befinden. Verwendet werden Ihre Daten erst, wenn es zu einem Vertragsabschluss kommt. Ihr Arbeitgeber wird über Ihre Kreditabsichten zu keinem Zeitpunkt von Finanzcheck informiert. Die Banken unterliegen dem Bankgeheimnis.',
        valueAlternative:
          'Ihre Angaben zu Ihrem Arbeitsverhältnis werden benötigt, um sicherzustellen, dass Sie sich in einem festen Beschäftigungsverhältnis befinden. Ihr Arbeitgeber wird von uns nicht kontaktiert.'
      }
    },
    previousEmployment: {
      employedLessThan2Years: {
        label: 'Weniger als zwei Jahre beim Arbeitgeber',
        value:
          'Da Sie seit weniger als zwei Jahren bei Ihrem aktuellen Arbeitgeber in Anstellung sind, werden zusätzlich die Angaben über Ihren vorherigen Arbeitgeber benötigt.'
      }
    }
  },
  income: {
    incomePrimary: {
      stable: {
        label: 'Banken mögen ein festes Einkommen',
        value:
          'Eine der wichtigsten Voraussetzungen für die Vergabe eines Kredites ist ein regelmäßiges Einkommen. Denn nur wer nachweisen kann, dass er über ausreichend Einkünfte verfügt, bekommt einen Kredit von einer Bank.',
        valueAlternative:
          'Damit Sie einen Kredit erhalten, müssen Sie über ein regelmäßiges und bestenfalls gleich bleibendes Einkommen verfügen.'
      }
    },
    incomeOthers: {
      solvency: {
        label: 'Weitere Einkommen verbessern Ihre Bonität',
        value:
          'Beziehen Sie außerdem sonstige regelmäßige Einkünfte, z.B. Kindergeld, Unterhalt oder Rente? Diese werden Ihrem Haupteinkommen hinzugerechnet und können sich positiv auf Ihre Kreditkonditionen auswirken.'
      }
    }
  },
  expenses: {
    solvency: {
      label: 'Ausgaben können sich auch positiv auf Ihre Bonität auswirken',
      value:
        'Regelmäßige Ausgaben beeinflussen Ihre Bonität, denn sie schmälern Ihr verfügbares Einkommen. Dennoch stufen Banken Ausgaben für die Beurteilung Ihrer Kreditwürdigkeit unterschiedlich ein. So wird das eigene Auto von einigen Banken als Zeichen dafür gewertet, dass Sie in der Lage sind, finanziell dafür aufzukommen. Und das wirkt sich durchaus positiv auf Ihre Bonität aus.',
      valueAlternative:
        'Mit den Informationen zu Ihren Ausgaben berechnen wir Ihr frei verfügbares Einkommen und ermitteln somit passende Kreditangebote.'
    }
  },
  activeLoans: {
    household: {
      label:
        'Bestehende Kredite können sich positiv auf Ihre Bonität auswirken',
      value:
        'Banken machen eine Haushaltsrechnung. Deshalb ist es für sie wichtig zu wissen, wie hoch Ihre bestehenden Kreditverpflichtungen sind. Wer sich bisher an den Rückzahlungsplan eines anderen Kredites gehalten hat, gilt bei Banken als verlässlicher Kreditnehmer.'
    }
  },
  rdi: {
    coverage: {
      label: 'Eine Absicherung kann sich lohnen',
      value:
        'Mit einer Ratenschutzversicherung können Sie sich gegen Risiken wie Arbeitslosigkeit, Arbeitsunfähigkeit und Todesfall absichern. Im versicherten Risikofall übernimmt die Versicherung die Zahlung der Raten. Im Todesfall wird der noch offene Restkreditbetrag von der Versicherung an die Bank komplett zurückbezahlt. Mit dem Abschluss der Versicherung erhöht sich Ihre monatliche Rate um den jeweiligen Versicherungsbeitrag.',
      valueAlternative:
        'Möchten Sie sich und Ihre Familie im Falle einer Arbeitslosigkeit, Arbeitsunfähigkeit oder auch im Todesfall absichern, lohnt ein Versicherungsschutz. Im Risikofall übernimmt die Versicherung die Zahlung Ihrer offenen Kreditraten. Ihre Auswahl können Sie jederzeit ändern.'
    }
  },
  loanPayout: {
    easyPayout: {
      label: 'Auszahlung Ihres Kredits',
      // hardcoded in taurine because willy doesn't support tags yet
      value: ''
    }
  }
}

export default introduction
