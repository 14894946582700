export const DAC_IDLE_STEPS = [
  '',
  'IBAN_GATE_STEP',
  'BANK_SELECTION_STEP',
  'DAC_SUCCESS_STEP'
]

const DAC_IDLE_STATES = ['initial', 'disagreed', 'success']
export const isDacIdleState = (state: string) => DAC_IDLE_STATES.includes(state)

export enum DACEvent {
  STATE_CHANGE_AGREED = 'dac_onStateChange_yes',
  STATE_CHANGE_DISAGREED = 'dac_onStateChange_no',
  ON_SUCCESS = 'dac_onSuccess'
}
