import TVIcon from '@mui/icons-material/TvOutlined'
import Search from '@mui/icons-material/SearchOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'

export enum BrandTracking {
  TV = 'TV',
  SEARCH_ENGINE = 'SUCHMASCHINE',
  BANNER_AD = 'WERBUNG',
  SOCIAL_MEDIA = 'SOZIALE',
  RADIO = 'RADIO',
  YOUTUBE = 'YOUTUBE',
  RECOMMENDED = 'EMPFOHLEN'
}

const brandTrackingList = [
  { label: 'TV-Werbung', value: BrandTracking.TV, icon: TVIcon },
  {
    label: 'Suchmaschine (z.B. Google)',
    value: BrandTracking.SEARCH_ENGINE,
    icon: Search
  },
  {
    label: 'Banner-Werbung im Internet',
    value: BrandTracking.BANNER_AD,
    icon: CampaignOutlinedIcon
  },
  {
    label: 'Soziale Medien (z.B. Facebook)',
    value: BrandTracking.SOCIAL_MEDIA,
    icon: ConnectWithoutContactOutlinedIcon
  },
  {
    label: 'Radio-Werbung',
    value: BrandTracking.RADIO,
    icon: RadioOutlinedIcon
  },
  {
    label: 'Youtube',
    value: BrandTracking.YOUTUBE,
    icon: SmartDisplayOutlinedIcon
  },
  {
    label: 'Empfohlen oder bekannte Marke',
    value: BrandTracking.RECOMMENDED,
    icon: GroupsOutlinedIcon
  }
]

export default brandTrackingList
