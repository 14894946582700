import { getAge } from 'utils/date'
import { useStores } from './useStores'
import { AgeGroup, MIDDLE_AGE, MIN_AGE, YOUNG_AGE } from 'constants/components'

export function useAgeGroup() {
  const {
    traversal: {
      data: {
        debtors: {
          primary: {
            personal: { birthday }
          }
        }
      }
    }
  } = useStores()

  const debtorsAge = getAge(birthday)

  if (debtorsAge < MIN_AGE) {
    return AgeGroup.MIN_AGE_GROUP
  }

  if (debtorsAge < YOUNG_AGE) {
    return AgeGroup.YOUNG_AGE_GROUP
  }

  if (debtorsAge < MIDDLE_AGE) {
    return AgeGroup.MIDDLE_AGE_GROUP
  }

  return AgeGroup.OLD_AGE_GROUP
}
