import { useEffect, memo, useState, useCallback, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Grid, { GridSize } from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import SaveButton from 'components/SaveButton/SaveButton'
import SaveButtonDialog, {
  SaveButtonDialogState
} from 'components/SaveButton/SaveButtonDialog'
import { LoadingMode } from 'components/LoadingProvider'
import HotlineFallback from 'components/HotlineFallback'
import { useErrorState } from 'context/Error/hook'
import Wave from 'components/Wave'
import CategoryLayout from 'components/Layout/Category'
import PreviousPageErrors from 'components/PageErrors/PreviousPageErrors'
import { useSaveButton } from 'hooks/useSaveButton'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { useLoadingMode } from 'stores/utils/hooks/useLoadingMode'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { useDebouncedEffect } from 'hooks/useDebouncedEffect'
import { Button, Theme } from '@mui/material'
import { useAsyncEffect } from 'hooks/useAsyncEffect'
import LegalHint from 'components/LegalHint'
import { NavigateFunction } from 'react-router-dom'
import useEmotionStyles from 'hooks/useEmotionStyles'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { DACEvent } from 'components/DigitalAccountCheck/helpers'

type Props = {
  onBack?: () => void
  onNext: () => void
  trackWithOrigin: (event: AmplitudeEvent, payload: {}) => void
  navigate: NavigateFunction
  legalText: string
  isContinueWithoutDacVisible: boolean
  isBackButtonHidden: boolean
  isContinueButtonHidden: boolean
  sizeOfContinueButton: GridSize
  sizeOfBackButton: GridSize
  sizeOfSaveButton: GridSize
  submitLabel: string | ReactNode
  backLabel: string | undefined
  onNextWithTracking: () => void
  continueButtonColor?: 'primary' | 'secondary'
  showPreviousPageError?: boolean
  backButtonVariant?: 'text' | 'outlined' | 'contained'
  saveButtonVariant?: 'outlined' | 'contained'
  continueWithoutDacButtonVariant?: 'text' | 'outlined' | 'contained'
}

const styles = (
  theme: Theme,
  continueButtonColor: 'primary' | 'secondary'
) => ({
  flexBasisGrid: {
    flexBasis: 'auto'
  },
  button: {
    maxHeight: '48px',
    '@media (max-width: 768px)': {
      '&:hover': {
        backgroundColor: `${theme.palette[continueButtonColor].main} !important`
      }
    }
  },
  buttonFullHeight: {
    height: '100%',
    '& button': {
      height: '100%'
    }
  }
})

const FormNavigation = observer(
  ({
    onBack,
    onNext,
    trackWithOrigin,
    navigate,
    legalText,
    isContinueWithoutDacVisible,
    isBackButtonHidden,
    isContinueButtonHidden,
    sizeOfContinueButton,
    sizeOfBackButton,
    sizeOfSaveButton,
    submitLabel,
    backLabel,
    onNextWithTracking,
    continueButtonColor = 'primary',
    showPreviousPageError = true,
    backButtonVariant = 'outlined',
    saveButtonVariant = 'outlined',
    continueWithoutDacButtonVariant = 'text'
  }: Props) => {
    const {
      rootStore: {
        isInIframe,
        isFooterVisible,
        urlParams: { backUrl }
      }
    } = useRootStore()

    const {
      state: { message },
      hasCooldown
    } = useErrorState()

    const { ref, inView } = useInView()

    useDebouncedEffect(
      () => {
        if (inView) {
          trackInAmplitude(AmplitudeEvent.CtaIsVisible)
        }
      },
      300,
      [inView]
    )

    const {
      page: { isFirstDisplayedPage, getCurrentIndex, scrollToFirstError },
      navigation: { currentPageInSession },
      traversal: { data },
      fieldErrors
    } = useStores()

    const loadingMode = useLoadingMode()

    const [saveButtonDialogState, setSaveButtonDialogState] =
      useState<SaveButtonDialogState>('none')
    const { isSaveButtonVisible, save, isSaveButtonEnabled } = useSaveButton({
      onSuccess: () => {
        setSaveButtonDialogState('success')
      },
      onError: () => {
        setSaveButtonDialogState('error')
      }
    })

    useEffect(() => {
      setSaveButtonDialogState('none')
    }, [data, currentPageInSession])

    const currentIndex = getCurrentIndex()

    const scrollToError = useCallback(
      () => scrollToFirstError(isInIframe),
      [isInIframe, scrollToFirstError]
    )

    const [hasErrorsOnCurrentPage, setHasErrorsOnCurrentPage] = useState(false)
    const [previousErrorPageName, setPreviousErrorPageName] = useState<string>()

    useAsyncEffect(async () => {
      if (!currentPageInSession) {
        return
      }

      const errorsOnCurrentPage =
        await fieldErrors.getErrors(currentPageInSession)
      const hasErrorsOnCurrentPageFlag =
        Object.keys(errorsOnCurrentPage).length > 0

      setHasErrorsOnCurrentPage(hasErrorsOnCurrentPageFlag)

      const previousErrorPageNameFlag =
        await fieldErrors.findFirstErrorPage(currentIndex)

      setPreviousErrorPageName(previousErrorPageNameFlag)
    }, [currentPageInSession, loadingMode])

    useEffect(() => {
      if (loadingMode === LoadingMode.None && hasErrorsOnCurrentPage) {
        scrollToError()
      }
    }, [hasErrorsOnCurrentPage, loadingMode, scrollToError])

    const onContinueWithoutDacClick = () => {
      trackWithOrigin(AmplitudeEvent.OnDACWithoutClick, {})
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: DACEvent.STATE_CHANGE_DISAGREED,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
      onNext()
    }

    const { flexBasisGrid, button, buttonFullHeight } = useEmotionStyles(
      styles,
      continueButtonColor
    )
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const isFirstPage = isFirstDisplayedPage(currentPageInSession)

    if (!isFooterVisible) {
      return null
    }

    if (
      previousErrorPageName &&
      !hasErrorsOnCurrentPage &&
      showPreviousPageError
    ) {
      return (
        <PreviousPageErrors
          navigate={navigate}
          pageName={previousErrorPageName}
        />
      )
    }

    const backLabelText =
      !isInIframe || currentIndex > 0 || backUrl ? backLabel : undefined

    return (
      <CategoryLayout ref={ref}>
        {message && <HotlineFallback message={message} />}
        <SaveButtonDialog state={saveButtonDialogState} />
        <Grid
          container
          spacing={1}
          direction={isMobile || isSaveButtonVisible ? 'column' : 'row-reverse'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            className={flexBasisGrid}
            container
            item
            spacing={1}
            direction={isMobile ? 'column' : 'row-reverse'}
            xs={12}
          >
            {!isContinueButtonHidden && (
              <Grid item xs={12} sm={sizeOfContinueButton}>
                <Button
                  className={button}
                  size="large"
                  fullWidth
                  id="next-submit"
                  name="next-submit"
                  variant="contained"
                  color={continueButtonColor}
                  endIcon={
                    loadingMode === LoadingMode.None && !isFirstPage ? (
                      <ArrowForwardIcon />
                    ) : undefined
                  }
                  disabled={hasCooldown}
                  onClick={onNextWithTracking}
                  data-page={currentPageInSession}
                  data-page-first={isFirstPage}
                >
                  {loadingMode === LoadingMode.Blocking ? (
                    <Wave />
                  ) : (
                    submitLabel
                  )}
                </Button>
              </Grid>
            )}

            {isSaveButtonVisible && (
              <Grid
                item
                xs={12}
                sm={isContinueButtonHidden ? 12 : sizeOfSaveButton}
              >
                <div
                  className={buttonFullHeight}
                  data-testid="save-button-container-formNav"
                >
                  <SaveButton
                    isSaveButtonEnabled={isSaveButtonEnabled}
                    onClick={save}
                    variant={saveButtonVariant}
                  />
                </div>
              </Grid>
            )}
            {!isBackButtonHidden && (
              <Grid item xs={12} sm={sizeOfBackButton}>
                <Button
                  className={buttonFullHeight}
                  size="large"
                  type="button"
                  id="back-submit"
                  name="back-submit"
                  onClick={onBack}
                  color="primary"
                  variant={backButtonVariant}
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                >
                  {backLabelText}
                </Button>
              </Grid>
            )}

            {isContinueWithoutDacVisible && (
              <Grid
                item
                xs={12}
                data-testid="grid-item-next-submit-container"
                display="flex"
                alignItems="center"
              >
                <Button
                  className={buttonFullHeight}
                  size="large"
                  fullWidth
                  id="next-submit-without-dac"
                  name="next-submit-without-dac"
                  variant={continueWithoutDacButtonVariant}
                  color="primary"
                  disabled={hasCooldown}
                  onClick={onContinueWithoutDacClick}
                >
                  {loadingMode === LoadingMode.Blocking ? (
                    <Wave />
                  ) : (
                    'Weiter ohne Bankkonto'
                  )}
                </Button>
              </Grid>
            )}

            {currentPageInSession === 'contact' && (
              <Grid item>
                <LegalHint legalText={legalText} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CategoryLayout>
    )
  }
)

export default memo(FormNavigation)
